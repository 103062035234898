import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const RepairExperts = () => {
  return (
    <Layout>
      <SEO title="Your Repair Expert" description="" />
      <div className="consulting-wrapper wrapper py-5">
        <div className="row">
          <div className="col-12">
            <Image src="yourrepairexperts.PNG" />
          </div>
        </div>
        <h1 className="mt-3">Your Repair Experts</h1>
        <span>
          Your Repair Experts is your number 1 Source for finding the correct
          nearby contractual workers to complete your home improvement,
          maintenance and repair projects. There is no compelling reason to
          invest hours calling several contract workers from the nearby
          telephone directory or scanning for organizations on the web.
        </span>
        <p>The following are the achievements we are always proud of.</p>
        <ul>
          <li>Compare quotes - up to 4 Pro’s instantly</li>
          <li>Vast network of vendors</li>
          <li>100+ categories</li>
        </ul>
        <p>
          With the ease provided by Your Repair Experts, people have access to
          one-click quotes and quality service providers. Now they have ability
          to compare multiple quotes even at staying home. Not only for 2-3 but
          100+ categories.
        </p>
      </div>
    </Layout>
  )
}

export default RepairExperts
